const defaultProps = {
  keys: [],
};

function getColorBasedOnValue(value) {
  switch (value) {
    case "Disapproved":
      return "#B20001";
    case "Done":
      return "#3FC65C";
    case "Pending":
      return "#E1E35D";
    case "Liquidation":
      return "#9747FF";
    case "Paid":
      return "#CF6F00";
    case "Approved":
      return "#3FC65C";
    default:
      return "inherit";
  }
}
const formatCurrency = (balance) => {
  return new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "PHP",
  }).format(balance);
};

const formatCurrencyString = (balance) => {
  return new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "PHP",
  }).format(balance.replace(/,/g, ""));
};
function getColorBasedOnType(object) {
  const type = object.type;
  if (type === "Money In") {
    return "#3FC65C";
  } else if (type === "Money Out") {
    return "#B20001";
  }
  return "inherit";
}

function OutputString({ field, object, keys }) {
  const value = object[field];
  const color =
    getColorBasedOnValue(value) !== "inherit"
      ? getColorBasedOnValue(value)
      : getColorBasedOnType(object);

      let renderedValue;

      if (value && typeof value !== "object") {
        renderedValue =
          // field === "amounts" ||
          field === "balance" || field === "totalAmount"
            ? formatCurrency(value)
            : field === "balance"
            ? formatCurrencyString(value)
            : field === "balance"
            ? formatCurrency(value)
            : value;
      } else {
        const values = [];
        keys.forEach((key) => {
          const v = object[key];
          if (v && typeof v !== "object") {
            values.push(v);
          }
        });
        renderedValue = values.join(" ");
      }
    
      return <span style={{ color }}>{renderedValue}</span>;
    }

OutputString.defaultProps = defaultProps;

export default OutputString;

import React from "react";
import {
  InputFactory as Factory,
  RelatedFactory,
} from "nq-component";
import { findObjectUseCase, saveObjectUseCase } from "../../usecases/object";
import { saveFileUseCase, saveImageUseCase } from "../../usecases/file";
import Context from "../../AppContext";
import InputSelect from "../InputSelect";
import UserInputPointer from "../InputPointer";
import ModdedInputPointer from "../ModdedInputPointer";
import AccountsInputPointer from "../AccountsInputPointer";
import Parse from "../Parse";
import InputString from "../InputString"

const defaultProps = {};

function InputFactory({ type, _type, field, object, onChange, ...props }) {
  const context = React.useContext(Context);
  const value = object && object[field];

  function _onChange(field, value) {
    if (object) {
      object[field] = value;
    }
    onChange(value, field);
  }

  switch (_type || type) {
    case "Related":
      return (
        <RelatedFactory
          defaultValue={value}
          onChange={_onChange.bind(this, field)}
          schema={
            props.schema ||
            context.schemas.find((s) => s.collection === props.target)
          }
          schemas={context.schemas}
          field={field}
          {...props}
        />
      );
    case "Enums":
      return (
        <InputSelect
          defaultValue={value}
          onChange={_onChange.bind(this, field)}
          type={type.toLowerCase()}
          placeholder={
            props.placeholder ||
            (props.dynamic ? "Select of type " : "Select ") +
              (props.label || field)
          }
          {...props}
        />
      );
    case "String1":
      return (
        <InputString
          defaultValue={value}
          onChange={_onChange.bind(this, field)}
          type={type.toLowerCase()}
          {...props}
        />
      );

    case "UserInputPointer":
      return (
        <UserInputPointer
          defaultValue={value}
          onChange={_onChange.bind(this, field)}
          type={type.toLowerCase()}
          schema={
            props.schema ||
            context.schemas.find((s) => s.collection === props.target)
          }
          schemas={context.schemas}
          findObject={findObjectUseCase()}
          {...props}
        />
      );

    case "AccountsInputPointer":
      return (
        <AccountsInputPointer
          defaultValue={value}
          onChange={_onChange.bind(this, field)}
          type={type.toLowerCase()}
          schema={
            props.schema ||
            context.schemas.find((s) => s.collection === props.target)
          }
          schemas={context.schemas}
          findObject={findObjectUseCase()}
          {...props}
        />
      );

    case "ModdedInputPointer":
      return (
        <ModdedInputPointer
          defaultValue={value}
          onChange={_onChange.bind(this, field)}
          type={type.toLowerCase()}
          schema={
            props.schema ||
            context.schemas.find((s) => s.collection === props.target)
          }
          schemas={context.schemas}
          findObject={findObjectUseCase()}
          saveObject={saveObjectUseCase()}
          saveImage={saveImageUseCase()}
          saveFile={saveFileUseCase()}
          {...props}
        />
      );
    case "parseNumber":
      return (
        //  <InputString
        //   defaultValue={value}
        //   onChange={_onChange.bind(this, field)}
        //   type={type.toLowerCase()}
        //   {...props}
        // />
        // <input />
        // <InputSelect
        //   defaultValue={value}
        //   onChange={_onChange.bind(this, field)}
        //   type={type.toLowerCase()}
        //   placeholder={
        //     props.placeholder ||
        //     (props.dynamic ? "Select of type " : "Select ") +
        //       (props.label || field)
        //   }
        //   {...props}
        // />
        <Parse
          defaultValue={value}
          onChange={_onChange.bind(this, field)}
          type={type.toLowerCase()}
          {...props}
        />
      );

    default:
      return (
        <Factory
          type={type}
          _type={_type}
          field={field}
          object={object}
          schemas={context.schemas}
          onChange={onChange}
          findObject={findObjectUseCase()}
          saveObject={saveObjectUseCase()}
          saveImage={saveImageUseCase()}
          saveFile={saveFileUseCase()}
          {...props}
        />
      );
  }
}

InputFactory.defaultProps = defaultProps;
export default InputFactory;
import BaseListPage from "../../base/BaseListPage"
import AccountsPresenter from "./AccountsPresenter"
import {countObjectUseCase,
    deleteObjectUseCase,
    findObjectUseCase,
    upsertUseCase} from "../../usecases/object"
    import {
        addSchemaUseCase,
        updateSchemaUseCase,
        deleteSchemaUseCase,
      } from "../../usecases/schema/usecases";
import NavBar from "../../components/navbar"
import { exportCSVUseCase } from "../../usecases/csv/usecases";
import InfiniteScroll from "nq-component/dist/InfiniteScroll"
import InputFactory from "../../components/InputFactory"
import Search from "../../components/Search"
import Table from "../../components/Table"
import Button from "nq-component/dist/Button"
import dialog from "nq-component/dist/Modal/dialog"
import browseFile from "../../browseFile"
class Accounts extends BaseListPage{
    constructor(props){
        super(props)
        this.presenter = new AccountsPresenter(
            this,
            findObjectUseCase(),
            countObjectUseCase(),
            deleteObjectUseCase(),
      upsertUseCase(),
      exportCSVUseCase(),
      addSchemaUseCase(),
      updateSchemaUseCase(),
      deleteSchemaUseCase())
    }
    getCollectionName() {
        return "gmd_accounts";
      }
      closeDialog() {
        dialog.close();
      }
      onChangeFilter(type, value, field) {
        const where = {};
        switch (type) {
          case "Pointer":
            if (Object.keys(value).length > 0) {
              where[field] = { id: value.id };
            }
            break;
          case "Boolean":
            where[field] = value;
            break;
          default:
            where[field] = { $regex: value, $options: "i" };
        }
        this.setState({ where });
      }
      onClickImport() {
        browseFile("*").then((files) => {
          if (files.length > 0) {
            const file = files[0];
            this.presenter.onClickImport(file);
          }
        });
      }
    
      onClickExport() {
        this.presenter.onClickExport();
      }
      
  onCollapse(index,objects) {
    const formatCurrency = (amount) => {
      return new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "PHP",
      }).format(amount);
    };
    console.warn(index,objects)
    return (
      <div class="container mt-3"> 
        <div class="row"> 
          <div class="col"> 
            <div class="mb-2"> 
            <span class="ms-2 fw-bold">Account: </span> 
            <span class="ms-2">{objects?.name}</span> 
            </div> 

            <div class="mb-2"> 
            <span class="ms-2 fw-bold">Balance: </span> 
            <span class="ms-2">{formatCurrency(Number(objects?.balance))}</span> 
            </div> 
            <button onClick={this.onClickItem.bind(this, index)} class="btn btn-primary btn-sm" > EDIT </button> 
          </div> 
        </div> 
      </div>
    );
  }
  onClickItem(index) {
    this.presenter.onClickItem(index);
  }
    
    render(){
        const schema = this.getSchema(this.getCollectionName());
        const { objects, selected, count, progress } = this.state;
        // if (!schema) return <Progress />;
        console.log("l",objects)
        console.log("m",schema)
        const user = this.getCurrentUser();
        return(
            <>
            <NavBar />
        <div className="overflow-auto">
          <InfiniteScroll
            className="h-100"
            loadMore={this.loadMore.bind(this)}
            hasMore={!progress && count > objects.length}
          >
            <div className="p-3 p-lg-4">
              <div className="d-flex justify-content-between align-items-center">
                <h1 className="fw-bold text-capitalize">Accounts
                  {/* {schema.label || this.getCollectionName()} */}
                </h1>
                {selected.length > 0 ? (
                  <div>
                    <span className="ms-2">Selected: </span>
                    <span className="fs-sm text-nowrap">{selected.length}</span>
                    <span className="ms-1">of </span>
                    <span className="fs-sm text-nowrap">{count}</span>
                  </div>
                ) : (
                  <div>
                    <span className="ms-2">Total: </span>
                    <span className="fs-sm text-nowrap">{objects.length}</span>
                    <span className="ms-1">of </span>
                    <span className="fs-sm text-nowrap">{count}</span>
                  </div>
                )}
              </div>
              <div className="d-flex mt-3">
                {Object.keys(schema.filters || {}).map((field) => {
                  let { type, ...options } = schema.filters[field];
                  return (
                    <InputFactory
                      key={field}
                      className="ms-1"
                      type={type}
                      field={field}
                      where={{}}
                      onChange={this.onChangeFilter.bind(this, type)}
                      {...options}
                    />
                  );
                })}
              </div>
              <Search
                schemas={this.getSchemas()}
                className="mt-3"
                onSubmit={this.searchSubmit.bind(this)}
                fields={schema.fields}
              />
              <Table
                fields={schema.fields}
                objects={objects}
                selectable
                collapsable
                excludeFields={Object.keys(schema.fields).reduce(
                  (acc, key) => {
                    const options = schema.fields[key];
                    if (options.read === false) {
                      acc.push(key);
                    }
                    switch (options._type || options.type) {
                      case "Relation":
                      case "Array":
                      case "Object":
                      case "File":
                        acc.push(key);
                        break;
                      default:
                    }
                    return acc;
                  },
                  ["acl", "password"]
                )}
                selected={selected}
                onSelect={this.onSelect.bind(this)}
                onSelectAll={this.onSelectAll.bind(this)}
                progress={progress}
                onClickItem={this.onClickItem.bind(this)}
                onCollapse={this.onCollapse.bind(this)}
                className="mt-3"
              />
            </div>
          </InfiniteScroll>
        </div>
        
            </>
        )
    }
}

export default Accounts
import React from 'react';
import OutputString from "./type/OutputString";
import OutputDate from "./type/OutputDate";
import OutputBoolean from "./type/OutputBoolean";
import OutputImage from "./type/OutputImage";
import OutputPointer from "./type/OutputPointer";

function OutputFactory({type, _type, field, object, ...options}) {
    switch (_type || type) {
        case 'Email':
        case 'String':
        case 'Text':
        case 'Number':
        case 'Tel':
        case 'Enum':
            return <OutputString
                field={field}
                object={object}
                {...options}/>;
        case 'Boolean':
            return <OutputBoolean
                field={field}
                object={object}
                {...options}/>;
        case 'Date':
            return <OutputDate
                field={field}
                object={object}
                {...options}/>;
        case 'Image':
            return <OutputImage
                field={field}
                object={object}
                {...options}/>;
        case 'Pointer':
            return <OutputPointer
                field={field}
                object={object}
                {...options}/>;
        case 'Component':
            return object[field];
        default:
            return null;
    }
}

export default OutputFactory;

import React from "react";
import classNames from "../../classNames";
import { findObjectUseCase } from "../../usecases/object";

const noop = () => {};
const defaultProps = {
  loadOptions: noop,
  onChange: noop,
  onSearch: noop,
  onClickAdd: noop,
  onClickClear: noop,
  onFocus: noop,
  value: { label: "", value: "" },
  placeholder: "Select",
  dynamic: false,
};

// const options = ["aasas"];

function SelectSearch({
  className,
  value,
  placeholder,
  onChange,
  onSearch,
  onClickAdd,
  onClickClear,
  onFocus,
  focus,
  options,
  required,
  dynamic,
}) {
  const [search, setSearch] = React.useState("");
  const [text, setText] = React.useState("");
  const [isOpen, setOpen] = React.useState(false);
  const ref = React.useRef(null);
  const [fetchedStreets, setFetchedStreets] = React.useState([]);

  console.log("options", fetchedStreets);

  const fetchStreets = async () => {
    const query = {
      include: ["project_name"],
    };
    try {
      const data = await findObjectUseCase().execute("budget_request", query);
      setFetchedStreets(data);
    } catch (error) {
      console.error("Failed to fetch barangay streets:", error);
    }
  };

  React.useEffect(() => {
    fetchStreets();
  }, []);

  // handle click outside event
  const handleClickOutside = (event) => {
    if (ref.current && !ref.current.contains(event.target)) {
      setOpen(false);
      onFocus(false);
    }
  };

  React.useEffect(() => {
    // add when mounted
    document.addEventListener("mousedown", handleClickOutside);
    // return function to be called when unmounted
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);
  // when value change
  React.useEffect(() => {
    setText(value.label);
    setSearch("");
  }, [value]);

  function _onChange(e) {
    // Get the new character input by the user by removing the previous text from the current value.
    const character = e.target.value.replace(text, "");
    if (search === "") {
      e.target.value = character;
    }
    // set the new character input by the user
    setSearch(e.target.value);
    setText(e.target.value);
    onSearch(e.target.value);
  }

  function onClick(e) {
    // e.target.setSelectionRange(0, 0);
    if (!isOpen) {
      e.target.blur();
    }
    setOpen(!isOpen || focus);
  }

  function onSelect(value, index) {
    // Concatenate the 'br' and 'remark' properties of the selected option
    const displayText = `${value.br} ${value.project_name.name}`;

    // Update the text state with the concatenated string
    setText(displayText);

    onChange(value, index);
    setOpen(false);
    if (search) {
      // Reset the options if has search
      onSearch("");
    }
  }

  function _onFocus(e) {
    if (!focus) {
      e.target.blur();
    }
    onFocus(true);
  }

  function onClickIcon(e) {
    if (text) {
      onClickClear(e);
    } else if (dynamic) {
      onClickAdd(e);
    } else {
      onClick(e);
    }
  }

  const style = { cursor: isOpen && focus ? "text" : "default" };
  const icon = text ? "bi bi-x" : dynamic ? "bi bi-plus" : "bi bi-chevron-down";
  return (
    <div ref={ref} style={{ position: "relative" }}>
      <div className="input-group">
        <input
          onClick={onClick}
          type="text"
          className={classNames(className, "form-control border-end-0 pe-0")}
          placeholder={placeholder}
          value={text}
          onChange={_onChange}
          onFocus={_onFocus}
          style={style}
          required={required}
        />
        <button onClick={onClickIcon} className="btn btn-link" type="button">
          <i className={icon}></i>
        </button>
      </div>

      {isOpen && (
        <ul
          className="list-group rounded-0 bg-white"
          style={{
            position: "absolute",
            top: "100%",
            width: "100%",
            maxHeight: "200px",
            overflowY: "auto",
            zIndex: 1000,
          }}
        >
          {fetchedStreets.map((option, index) => (
            <li
              // key={option.value}
              type="button"
              className="list-group-item list-group-item-action"
              onClick={() => onSelect(option, index)}
            >
              #{option.br} {option.project_name.name}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
}

SelectSearch.defaultProps = defaultProps;
export default SelectSearch;

// import React from "react";
// import classNames from "../../classNames";

// const noop = () => {};
// const defaultProps = {
//   type: "text",
//   strict: false,
//   onChange: noop,
// };

// function Parse({ className, strict, onChange, uppercase, ...props }) {
//   function onInput(e) {
//     const value = e.target.value;
//     const character = value.charAt(value.length - 1);
//     // only string
//     if (strict && !/^[a-zA-Z]*$/.test(character)) {
//       return;
//     }
//     if (uppercase) {
//       e.target.value = value.toUpperCase();
//     }
//     onChange(e.target.value);
//   }
//   return (
//     <div>
//       <input
//         className={classNames("form-control")}
//         onInput={onInput}
//         {...props}
//       />
//       <br />
//     </div>
//   );
// }
// Parse.defaultProps = defaultProps;
// export default Parse;
import React from "react";
import classNames from "../../classNames";

const noop = () => {};
const defaultProps = {
  type: "text",
  strict: false,
  onChange: noop,
};

function Parse({ className, type, strict, onChange, uppercase, ...props }) {
  // Function to format numbers with commas
  
  const formatNumber = (value) => {
    return value.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    
  };

  function onInput(e) {
    const input = e.target;
    const value = input.value;
    const selectionStart = input.selectionStart;
  
    // Strip non-numeric characters except for the decimal point
    let strippedValue = value.replace(/[^\d.]/g, "");
  
    // Ensure there's only one decimal point
    const decimalCount = strippedValue.split('.').length - 1;
    if (decimalCount > 1) {
      strippedValue = strippedValue.slice(0, strippedValue.lastIndexOf('.'));
    }
  
    // Format the number with commas
    const formattedValue = formatNumber(strippedValue);
  
    // Calculate new cursor position after formatting
    const diff = formattedValue.length - value.length;
    const newSelectionStart = selectionStart + diff;
  
    // Update the input value with formatted value
    input.value = formattedValue;
  
    // Restore cursor position
    input.setSelectionRange(newSelectionStart, newSelectionStart);
  
    // Parse the value as a number
    const numericValue = parseFloat(strippedValue);
  
    if (!isNaN(numericValue)) {
      if (uppercase) {
        input.value = input.value.toUpperCase();
      }
      onChange(numericValue); // Pass the parsed number to onChange
    } else {
      // Handle invalid input, like clearing the input field or showing an error message
      input.value = "";
      onChange(0); // Or any default value you prefer for invalid input
    }
  }
  
  
  
  

  return (
    <div>
      <input
        className={classNames("form-control", className)}
        onInput={onInput}
        {...props}
      />
      <br />
    </div>
  );
}
Parse.defaultProps = defaultProps;
export default Parse;

import React from "react";
import FormFactory from "../FormFactory";

function ModdedFormDialog({ schema, object, onSave, onBack, findObject, saveObject, saveImage, saveFile, schemas, onCancel, ...props }) {
    const [change, setChange] = React.useState({});

    function onChange(value, field) {
        setChange({
            ...change,
            [field]: value,
        });
    }

    async function onSubmit(e) {
        e.preventDefault();
        try {
            const object = await saveObject.execute(schema.collection, change);
            onSave(object);
        } catch (error) {
            console.error("FAILED SEND", error);
        }
    }

    return (
        <div className="px-2">
            <form onSubmit={onSubmit}>
                <div className="row g-2">
                    <FormFactory
                        className="col-md-6"
                        schema={schema}
                        object={object}
                        onChange={onChange}
                        findObject={findObject}
                        saveObject={saveObject}
                        saveFile={saveFile}
                        saveImage={saveImage}
                        schemas={schemas}
                        excludeFields={["id", "createdAt", "updatedAt", "acl"]}
                        {...props} />
                </div>
                <div className="d-flex justify-content-between mt-3">
                    <button
                        type="submit"
                        className="btn fs-sm me-3"
                        style={{ background: "#FFC03F" }}>Submit
                    </button>
                    <button
                        type="button"
                        className="btn btn-light fs-sm"
                        style={{ background: "#DDDDDD" }}
                        onClick={onCancel}>CANCEL
                    </button>
                </div>
            </form>
        </div>
    )
}

export default ModdedFormDialog;
import React from "react";
import Progress from "../Progress";
import classNames from "../../classNames";
import Head from "./Head";
import Body from "./Body";

const noop = () => {};
const defaultProps = {
  fields: {},
  objects: [],
  excludeFields: [],
  selected: [],
  progress: false,
  onClick: noop,
  onClickItem: noop,
  onSelect: noop,
  onSelectAll: noop,
  readOnly: false,
  actions: [],
  actionsList: [],
  selectable: false,
  collapsable: false,
  onCollapse: noop,
  onLongPress: noop,
};

function Table({
  className,
  fields,
  objects,
  progress,
  onClick,
  onClickItem,
  selected,
  onSelect,
  onSelectAll,
  excludeFields,
  actions,
  actionsList,
  setRef,
  selectable,
  onCollapse,
  collapsable,
  onLongPress,
  ...props
}) {
  const fieldImage = Object.keys(fields).find(
    (field) =>
      (fields[field]._type === "Image" && fields[field].read !== false) ||
      fields[field].detail
  );

  return (
    <div className="position-relative">
      <div
        className={classNames("table-responsive shadow-sm", className)}
        ref={setRef}
        {...props}
      >
        <table className="table mb-0 w-100 table-striped">
          <thead style={{ backgroundColor: "#EBBD2F" }}>
            <Head
              selectable={selectable}
              fields={fields}
              excludeFields={excludeFields}
              actionsList={actionsList}
              selected={selected}
              onSelectAll={onSelectAll}
              objects={objects}
            />
          </thead>
          <tbody className="bg-white">
            {objects.length === 0 && !progress && (
              <tr>
                <td
                  className="text-center fs-lg"
                  colSpan={Object.keys(fields).length + 1}
                >
                  No Data Found
                </td>
              </tr>
            )}
            <Body
              objects={objects}
              fields={fields}
              excludeFields={excludeFields}
              selected={selected}
              selectable={selectable}
              onSelect={onSelect}
              onClick={onClick}
              onLongPress={onLongPress}
              actionsList={actionsList}
              collapsable={collapsable}
              onCollapse={onCollapse}
              actions={actions}
              onClickItem={onClickItem}
              fieldImage={fieldImage}
            />
            {progress && (
              <tr>
                <td colSpan={Object.keys(fields).length + 1}>
                  <Progress className="fs-sm">Loading ...</Progress>
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
}

Table.defaultProps = defaultProps;
export default Table;

import BaseFormPresenter from "../../base/BaseFormPresenter";

class AccountsFormPresenter extends BaseFormPresenter {

    onChangeObject(object) {
        this.change = object;
        this.object = object;
    }
}

export default AccountsFormPresenter;
